import React, { useState, useEffect, useCallback, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include Bootstrap CSS
import 'react-awesome-slider/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductList.css';
import { SlHeart } from "react-icons/sl";
import { IoClose } from "react-icons/io5";
import { FiFilter } from 'react-icons/fi'; // For filter icon
import { Button, Offcanvas, Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from "../../components/Loader"; // Import the Loader component
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import _ from 'lodash';
import Cookies from 'js-cookie';
import AuthModal from '../../components/AuthModal';

function ProductList({ setSelectedWishlistCount }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]); // Holds products
  const [page, setPage] = useState(0); // Tracks the current page
  const [loading, setLoading] = useState(false); // Loading state
  const [loading2, setLoading2] = useState(false); // Loading state
  const [allLoaded, setAllLoaded] = useState(false); // Check if all products are loaded
  const [selectedOption, setSelectedOption] = useState('Recommended');
  const options = ['Recommended', 'Price: Low to High', 'Price: High to Low', 'New Arrivals'];
  const [selected, setSelected] = useState([]);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [collections, setCollections] = useState([]);
  const [color, setColors] = useState([]);
  const [fit, setFits] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [pattern, setPatterns] = useState([]);
  const [occasion, setOccasions] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const LIMIT = 10;
  const hasFetched = useRef(false);
  const token = Cookies.get('sessionToken');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [favProducts, setFavProducts] = useState([]);
  // const [userWishlist, setUserWishlist] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  useEffect(() => {
    axios.get(baseURL + "/website_product_filters").then((res) => {
      if(res.data.status === 'success'){
        setCollections(res.data.categories)
        setColors(res.data.colors)
        setFits(res.data.fits)
        setSizes(res.data.sizes)
        setPatterns(res.data.patterns)
        setOccasions(res.data.occasions)
        setFabrics(res.data.fabrics)
      }
    })
  },[])

  const handleCloseMobileFilter = () => setShowMobileFilter(false);
  const handleShowMobileFilter = () => setShowMobileFilter(true);
  const addToCart = (id) => {
    navigate('/CartPage', { state: { id } });
  }
  const sliderSettings = {
    dots: true,
    autoplay: false,
    infinite: true,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }
  // Convert selected filters into API query parameters
  const buildFilterParams = () => {
    const filterParams = {};
    selected.forEach((filter) => {
      const [category, option] = filter.split(': ');
      if (!filterParams[category]) {
        filterParams[category] = [];
      }
      filterParams[category].push(option);
    });

    // Convert arrays into comma-separated strings
    Object.keys(filterParams).forEach((key) => {
      filterParams[key] = filterParams[key].join(',');
    });

    return filterParams;
  };
  // Load products and update state
  const fetchProducts = async () => {
    if (loading || allLoaded) return; // Prevent duplicate calls
    setLoading(true);
    setLoading2(true);
    try {
      let user_id = '';
      if(token !== undefined){
        user_id = JSON.parse(token)['id'];
      }
      const filterParams = buildFilterParams();
      const response = await axios.get(baseURL + "/website_product_list", {
        params: { limit: LIMIT, page: page,sort: selectedOption, ...filterParams, user_id:user_id },
      });
      if (response.data.products.length > 0) {
        setData((prevData) => [...prevData, ...response.data.products]); // Append new products
        setPage((prevOffset) => prevOffset + LIMIT); // Increment page number
        setFavProducts(response.data.user_whishlist)
      } else {
        setAllLoaded(true); // No more products to load
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoading2(false);
    }
  };

  // Debounced scroll handler
  const handleScroll = useCallback(
    _.debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 500 >=
        document.documentElement.scrollHeight
      ) {
        fetchProducts();
      }
    }, 300),
    [loading, allLoaded]
  );

  const handleSelect = (event) => {
    setData([])
    setAllLoaded(false);
    setPage(0)
    hasFetched.current = false;
    setSelectedOption(event.target.value);
  };

  const addToFav = (productId) => {
    if(token !== undefined){
      setLoading2(true)
      const foundUser = JSON.parse(token);
      let selectToggle = '0';
      setFavProducts((prevFavs) => {
        if (prevFavs.includes(productId.toString())) {
          // Remove the product from favorites
          selectToggle = '0'
          return prevFavs.filter((id) => id !== productId.toString());
        } else {
          selectToggle = '1'
          // Add the product to favorites
          return [...prevFavs, productId.toString()];
        }
      });
      setTimeout(async () => {
        const response = await axios.post(baseURL + "/add_wishlist", {
          prod_id: productId.toString(), user_id: foundUser['id'],selectToggle:selectToggle
        });
        setSelectedWishlistCount(selectToggle);
        setLoading2(false)
      }, 0);
    }else{
      setIsModalOpen(true);
    }
  }
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true; // Mark as fetched
      fetchProducts(); // Load initial products
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [selected, selectedOption]);

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  function FilterSection({ title, options, selectedOptions, onSelectOption }) {
    const [isOpen, setIsOpen] = useState(true);
  
    const toggleSection = () => setIsOpen(!isOpen);
  
    return (
      <div className="filter-section">
        <div className="filter-header" onClick={toggleSection}>
          <span>{title}</span>
          <span>{isOpen ? '▼' : '▲'}</span>
        </div>
        {isOpen && (
          <div className="filter-options">
            {options.map((option, index) => (
              <label key={index} className={`filter-option ${option.count === 0 ? '' : 'filter-option2'}`}>
                  <div>
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(`${title}: ${option.name}`)}
                    onChange={() => onSelectOption(title, option.name)}
                    disabled={option.count === 0}
                  />
                  <span className={selectedOptions.includes(`${title}: ${option.name}`) ? 'selected' : ''}>
                    {option.name}
                  </span></div>
                <span className="count">({option.count})</span>
              </label>
            ))}
          </div>
        )}
      </div>
    );
  }

  const handleSelectOption = (category, option) => {
    const key = `${category}: ${option}`;
    setSelected((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
    setData([]); // Clear product data
    setAllLoaded(false); // Reset allLoaded state
    setPage(0); // Reset page number
    hasFetched.current = false; // Allow new fetch
  };

  const clearAll = () => {
    setData([]); // Clear product data
    setAllLoaded(false); // Reset allLoaded state
    setPage(0); // Reset page number
    hasFetched.current = false; // Allow new fetch
    setSelected([])
  };
  function FilterSectionContainer() {
    return (
      <div className="filters-container">
      {selected.length > 0 && (
        <div className="selected-filters">
          <div className="refine-header">
            <span>YOUR PICKS</span>
            <button onClick={clearAll} className="clear-all-btn">
              Clear All
            </button>
          </div>
          <div style={{paddingBottom:"8px"}}>
            {selected.map((item, index) => {
              const [category, option] = item.split(': ');
              return (
              <div key={index} className="selected-item">
                <span>{category}: <b>{option}</b></span>
                <button className='cancel-selected-filter' onClick={() => setSelected(selected.filter((i) => i !== item))}><IoClose size={18}/></button>
              </div>
              )
            })}
          </div>
        </div>
      )}
      <FilterSection
        title="Collections"
        options={collections}
        disabled
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Size"
        options={sizes}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Fit"
        options={fit}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Pattern"
        options={pattern}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Color"
        options={color}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Occasion"
        options={occasion}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Fabric"
        options={fabrics}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
    </div>
    )
  }
  
  return (
    <>
    {loading2 ? <Loader /> : ""}
    <div className='sort-top-menu'>
      <div className="your-pick-container d-none" onClick={handleShowMobileFilter}>
        <span>Your Picks &nbsp;</span>
        <FiFilter size={20} style={{ cursor: 'pointer' }} />
      </div>
      <Offcanvas show={showMobileFilter} onHide={handleCloseMobileFilter} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FilterSectionContainer />
        </Offcanvas.Body>
      </Offcanvas>
      <div className="sort-by-dropdown">
        <label htmlFor="sort" className="dropdown-label">Sort By:</label>
        <select id="sort" value={selectedOption} onChange={handleSelect}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className="product-page">
        <div className="filters">
          <FilterSectionContainer />
        </div>
      <div className="product-grid">
        {data.map((product) => (
            <div className="product-card">
                <Slider {...sliderSettings}>
                  {product.images.map((image, index) => (
                    <div key={index} className="image-container">
                      <img src={`https://admin.thirtyseven.in/assets/images/product/${image}`} alt={`Slide ${index}`} className="product-image" />
                    </div>
                  ))}
                </Slider>
                <h6 style={{marginBottom:"0px"}}>{product.name}</h6>
                <span className='d-flex gap-1 justify-content-center mobile-price-font p-1'>
                  <lable style={{color:"black",fontWeight:"bold"}}>₹{product.price}</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{product.price_original}</lable>
                  <lable style={{color:"green"}}>{product.discount}% Off</lable>
                </span>
                <div className='product-button'>
                  <button className={`${favProducts.includes(product.id.toString()) ? '' : 'product-button-heart'}`} onClick={(e) => addToFav(product.id.toString())}><SlHeart /></button>
                  <button onClick={(e) => addToCart(product.id.toString())}>Add to Bag</button>
                </div>
            </div>
        ))}
        {loading && <p>Loading more products...</p>}
        {allLoaded && <p>No more products to load.</p>}
      </div>
    </div>
    <AuthModal show={isModalOpen} onClose={closeModal} />
    </>
  );
}

export default ProductList;
