import React, {useEffect} from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ShippingPolicy = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return (
    <Box sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom>
      Shipping Policy
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Contact Form Section */}
        <Grid item >
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
                <ul className="list-unstyled p-2">
                    <li>The orders for the user are shipped through registered domestic courier companies and/or speed post 
                        only. Orders are delivered within 7-10 working days from the date of the order. If an exchange is approved, 
                        the product will be delivered within 7-10 working days and/or the payment will be processed as per the delivery 
                        date agreed upon at the time of order confirmation, subject to courier company/post office norms. 
                        Platform Owner shall not be liable for any delay in delivery by the courier company / 
                        postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of 
                        purchase. Delivery of our services will be confirmed on your email ID as specified at the time of 
                        registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), 
                        the same is not refundable.</li>
                </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShippingPolicy;
