import React,{useState, useEffect} from 'react';
import { Form, Container, Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include Bootstrap CSS
import 'react-awesome-slider/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import h_shirts from '../../img/h-Shirts.jpg';
import h_accessories from '../../img/h-Accessories.jpg';
import h_bottoms from '../../img/h-Bottoms.jpg';
import h_innerwear from '../../img/h-Innerwear.jpg';
import h_jeans from '../../img/h-Jeans.jpg';
import h_shorts from '../../img/h-Shorts.jpg';
import h_t_shirts from '../../img/h-T-Shirts.jpg';
import p_logo from '../../img/Logo-GIF-Transparent.gif';
import shirts from '../../img/shirts.jpg';
import pants from '../../img/pants.jpg';
import t_shirts from '../../img/t-shirts.jpg';
import hoodies from '../../img/hoodies.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Loader from "../../components/Loader";
import { useMediaQuery } from '@mui/material';

function HomeForm() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bannerFirst, setBannerFirst] = useState([]);
  const [mobileBannerFirst, setMobileBannerFirst] = useState([]);
  const [first10Records, setFirst10Records] = useState([]);
  const [last10Records, setLast10Records] = useState([]);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = () => {
    navigate('/ProductList');  // Navigate to the About page on click
  };
  const addToCart = (id) => {
    navigate('/CartPage', { state: { id } });
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    axios.get(baseURL + "/website_home_banners_product").then((res) => {
      if(res.data.status === 'success'){
        setBannerFirst(res.data.banner_data)
        setMobileBannerFirst(res.data.mobile_banner_data)
        setFirst10Records(res.data.first_10_records)
        setLast10Records(res.data.last_10_records)
      }
      setLoading(false);
    })
  },[])

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow`}
        onClick={onClick}
      >
      </div>
    );
  }; 
  const categories_second = [
    { name: 'Shirts', image: h_shirts },
    { name: 'Jeanse', image: h_jeans },
    { name: 'T-Shirts', image: h_t_shirts },
    { name: 'Bottoms', image: h_bottoms },
    { name: 'Shorts', image: h_shorts },
    { name: 'Innerwear', image: h_innerwear },
    { name: 'Accessories', image: h_accessories }
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  // useEffect(() => {
  //   Swal.fire({
  //     title: '<strong>We’re Launching Soon!</strong>',
  //     html: `
  //       <div style="text-align: center; font-size: 16px; line-height: 1.6;">
  //         <p>✨ <b>Welcome to the Future of Fashion!</b> ✨</p>
  //         <p>Our exclusive collection is coming your way soon. Stay tuned for:</p>
  //         <ul style="list-style-type: none; padding: 0;">
  //           <li>🛍️ Trendy Apparel</li>
  //           <li>🧥 Unique Designs</li>
  //           <li>🚀 Lightning-Fast Delivery</li>
  //         </ul>
  //         <p style="margin-top: 10px;">👕 <b>See you soon in style!</b></p>
  //       </div>
  //     `,
  //     icon: 'info',
  //     confirmButtonText: 'Notify Me!',
  //     confirmButtonColor: '#3085d6',
  //     backdrop: `rgba(0,0,0,0.6)`,
  //     allowOutsideClick: false,
  //   });
  // }, []);
  const settings11 = {
    autoplay: true,
    infinite: true,
    speed: 500,
    dots: false,
    lazyLoad: 'ondemand',
  }
  const categories = [
    { name: 'Shirts', image: shirts },
    { name: 'T-Shirts', image: t_shirts },
    { name: 'Pants', image: pants },
    { name: 'Hoodies', image: hoodies },
  ];
  
  return (
    <div >
      {loading ? <Loader /> : ""}
      <div className="category-menu">
        {categories.map((category, index) => (
          <div key={index} className={`category-item ${category.name !== 'Shirts' ? 'disabled' : ''}`} onClick={handleClick}>
            <LazyLoadImage
              src={category.image}
              alt={category.name}
              className="category-image"
              effect="blur"
            />
            <span className="category-label">{category.name}</span>
          </div>
        ))}
      </div>
      <div className='home-search-box'>
          <div className='bannerImages'>
              {!isMobile ? (
                <Slider {...settings11}>
                  {bannerFirst.map((each, index) => (
                    <LazyLoadImage src={each} alt="Image description" className="banner-image p-3" effect="blur"/>
                  ))}
                </Slider>
              ):(
                <Slider {...settings11}>
                  {mobileBannerFirst.map((each, index) => (
                    <LazyLoadImage src={each} alt="Image description" className="banner-image p-3" effect="blur"/>
                  ))}
                </Slider>
              )}
          </div>
      </div>
      <h3 className='home_header mt-3 d-flex justify-content-center'>Urban Vibes</h3>
      <Slider {...settings2}>
        {first10Records.map((each, index) => (
          <div className="image-text-container" onClick={(e) => addToCart(each['_id'].toString())}>
            <LazyLoadImage src={`https://admin.thirtyseven.in/assets/images/product/${each['images'][0]}`} alt="Image description" className="zoom-image" effect="blur"/>
            <div className="text-content">
              <h6 className='mobile-product-name'>{each['name']}</h6>
              <span className='d-flex gap-1 mobile-price-font'>
                <lable style={{color:"black"}}>₹{each['sell_price']}</lable>
                <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{each['mrp']}</lable>
                <lable style={{color:"green"}}>{each['discount']}% OFF</lable>
              </span>
            </div>
          </div>
        ))}
      </Slider>
      <h3 className='home_header mt-4 d-flex justify-content-center'>Most Trending</h3>
      <Slider {...settings2}>
        {last10Records.map((each, index) => (
          <div className="image-text-container" onClick={(e) => addToCart(each['_id'].toString())}>
            <LazyLoadImage src={`https://admin.thirtyseven.in/assets/images/product/${each['images'][0]}`} alt="Image description" className="zoom-image" effect="blur"/>
            <div className="text-content">
              <h6 className='mobile-product-name'>{each['name']}</h6>
              <span className='d-flex gap-1 mobile-price-font'>
                <lable style={{color:"black"}}>₹{each['sell_price']}</lable>
                <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{each['mrp']}</lable>
                <lable style={{color:"green"}}>{each['discount']}% OFF</lable>
              </span>
            </div>
          </div>
        ))}
      </Slider>
      
      {/* Categories Slider */}
      <h3 className='home_header p-2 mt-4 d-flex justify-content-center'>Gentlemen’s Wear</h3>
      <Slider {...settings}>
        {categories_second.map((each, index) => (
          <div className="image-text-container" key={index} onClick={handleClick}>
            <img src={each.image} alt="Image description" className="zoom-image3" />
            {/* <div className="gray-overlay">
              <p className="overlay-text">{each.name}</p>
            </div> */}
          </div>
        ))}
      </Slider>
      <Container fluid>
        <h4 className='mt-5'>Connected With</h4>
        <div className='row justify-content-center'>
          <div className='col-auto d-flex '>
            <h5 style={{marginRight:"5px"}}><img width={100} src={p_logo}/></h5>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomeForm;
