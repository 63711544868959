import React, {useEffect} from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ReturnPolicy = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return (
    <Box sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom>
      Return Policy
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Contact Form Section */}
        <Grid item >
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
                <h5>Introduction</h5>
                <ul className="list-unstyled p-2">
                    <li>We offer refund / exchange within first 7 days from the date of your purchase. If 7 days have passed 
                        since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become 
                        eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as 
                        you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale, 
                        then the item may not be eligible for a return / exchange. Further, only such items are replaced by us 
                        (based on an exchange request), if such items are found defective or damaged.</li>
                    <li>You agree that there may be a certain category of products / items that are exempted from returns or 
                        refunds. Such categories of the products would be identified to you at the item of purchase. For exchange 
                        / return accepted request(s) (as applicable), once your returned product / item is received and inspected 
                        by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further. 
                        If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will 
                        be processed in accordance with our policies.</li>
                </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReturnPolicy;
