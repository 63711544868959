import React from "react";
import { Card, CardContent, CardMedia, Typography, Button, Box, IconButton, Divider, useMediaQuery } from "@mui/material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTheme } from "@mui/material/styles";

const WishlistItem = ({ item, onRemove, onAddToBag }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card sx={{ mb: 2, p: 2 }}>
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "flex-start" : "center",textAlign:isMobile ?"center":"",alignItems:"center" }}>
        
        {/* Product Image - Appears below details on mobile */}
        <CardMedia
          component="img"
          sx={{
            width: 150,
            mt: isMobile ? 2 : 0,
          }}
          image={item.image}
          alt={item.name}
        />
        {/* Product Details */}
        <CardContent sx={{ flex: 1, pb: isMobile ? 0 : 2 }}>
          <Typography variant="h6">{item.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            Color: {item.color}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Article no.: {item.product_id}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>{item.price}</Typography>
        </CardContent>

        {/* Buttons - Appears below image on mobile */}
        <Box sx={{ flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "flex-start" : "center", mt: isMobile ? 2 : 0 }}>
          <Button
            variant="outlined"
            startIcon={<ShoppingCartOutlinedIcon />}
            onClick={() => onAddToBag(item.id)}
            sx={{ mb: isMobile ? 1 : 0 }}
          >
            Add to Bag
          </Button>
          <IconButton color="error" onClick={() => onRemove(item.id)} sx={{ ml: isMobile ? 0 : 2,mb: isMobile ? 1 : 0 }}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
    </Card>
  );
};

export default WishlistItem;
