import './App.css';
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeForm from './pages/home/HomeForm';
import ProductList from './pages/product/ProductList';
import CartPage from './pages/cart/CartPage';
import Checkout from './pages/checkout/Checkout';
import OrderConfirmation from './pages/orderconfirm/OrderConfirmation';
import ProfilePage from './pages/profile/ProfilePage';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import Wishlist from './pages/wishlist/Wishlist';
import ContactUs from './pages/contactus/ContactUs';
import PrivacyPolicy from './pages/policy/PrivacyPolicy';
import TermsConditions from './pages/policy/TermsConditions';
import RefundCancellation from './pages/policy/RefundCancellation';
import ReturnPolicy from './pages/policy/ReturnPolicy';
import ShippingPolicy from './pages/policy/ShippingPolicy';
import FAQ from './pages/policy/FAQ';
import PaymentStatus from './pages/checkout/PaymentStatus';
import ReturnExchangeForm from './pages/profile/ReturnExchangeForm';

function App() {
  const [selectedWishlistCount, setSelectedWishlistCount] = useState(null);
  const [selectedBagCount, setSelectedBagCount] = useState(null);
  return (
    <Router>
      <div>
        <Header selectedWishlistCount={selectedWishlistCount} selectedBagCount={selectedBagCount}/>
        <div className="rightSideContent">
          <Routes>
            <Route exact path="/" element={<HomeForm />} />
            <Route exact path="/ProductList" element={<ProductList setSelectedWishlistCount={setSelectedWishlistCount} />} />
            <Route exact path="/CartPage" element={<CartPage setSelectedBagCount={setSelectedBagCount}/>} />
            <Route exact path="/Checkout" element={<Checkout />} />
            <Route exact path="/OrderConfirmation" element={<OrderConfirmation />} />
            <Route exact path="/ProfilePage" element={<ProfilePage />} />
            <Route exact path="/Wishlist" element={<Wishlist setSelectedWishlistCount={setSelectedWishlistCount}/>} />
            <Route exact path="/ContactUs" element={<ContactUs />} />
            <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms_conditions" element={<TermsConditions />} />
            <Route exact path="/refund_cancellation" element={<RefundCancellation />} />
            <Route exact path="/return_policy" element={<ReturnPolicy />} />
            <Route exact path="/shipping_policy" element={<ShippingPolicy />} />
            <Route exact path="/FAQ" element={<FAQ />} />
            <Route exact path="/phonepe_status" element={<PaymentStatus />} />
            <Route exact path="/return_exchange" element={<ReturnExchangeForm />} />
            {/* Define more routes here if needed */}
          </Routes>
          <ScrollToTopButton />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
