import React, {useEffect} from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router-dom';

const RefundCancellation = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return (
    <Box sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Refund and Cancellation policy
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Contact Form Section */}
        <Grid item >
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
                <p>This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service 
                that you have purchased through the Platform. Under this policy:</p>
                <ul className="list-unstyled p-2">
                    <li>Cancellations will only be considered if the request is made 7 days of placing the order. However, 
                        cancellation requests may not be entertained if the orders have been communicated to such sellers / 
                        merchant(s) listed on the Platform and they have initiated the process of shipping them, or the 
                        product is out for delivery. In such an event, you may choose to reject the product at the doorstep.</li>
                    {/* <li>Thiry Seven Apparels does not accept cancellation requests for perishable items like flowers, 
                        eatables, etc. However, the refund / replacement can be made if the user establishes that the quality 
                        of the product delivered is not good.</li> */}
                    <li>In case of receipt of damaged or defective items, please report to our customer service team. The 
                        request would be entertained once the seller/ merchant listed on the Platform, has checked and 
                        determined the same at its own end. This should be reported within 7 days of receipt of products. 
                        In case you feel that the product received is not as shown on the site or as per your expectations, 
                        you must bring it to the notice of our customer service within 7 days of receiving the product. The 
                        customer service team after looking into your complaint will take an appropriate decision.</li>
                    <li>In case of complaints regarding the products that come with a warranty from the manufacturers, 
                        please refer the issue to them.</li>
                    <li>In case of any refunds approved by Thiry Seven Apparels, it will take 7 days for the refund to be 
                        credited to your original payment method.</li>
                </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RefundCancellation;
