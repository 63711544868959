import React, {useEffect, useState} from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, IconButton } from '@mui/material';
import { Phone, Email, AttachFile } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Swal from 'sweetalert2';
import Loader from "../../components/Loader"; // Import the Loader component

const ContactUs = () => {
  const { pathname } = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && !selectedFile.type.startsWith("image/")) {
      Swal.fire({
        icon: "error",
        title: "Invalid File",
        text: "Please select an image file (JPG, PNG, GIF, etc.).",
      });
      return;
    }
    setFile(selectedFile);
  };
  const handleSubmitContactUs = async(e) => {
    e.preventDefault();
    if(name === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter name',
      });
      return false;
    }
    if(email === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter email',
      });
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return false;
    }
    if(message === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter message',
      });
      return false;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    if (file) {
      formData.append("file", file);
    }
    setLoading(true);
    const response = await axios.post(`${baseURL}/submit_contact_us`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setLoading(false);
    if (response.data.status === "success") {
      Swal.fire({
        title: "Thank you!",
        text: "We have received your message and will respond within 24 hours.",
      });
      setName("");
      setEmail("");
      setMessage("");
      setFile(null);
    }
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
      {loading ? <Loader /> : ""}
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Contact Form Section */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Send Us a Message
              </Typography>
              <form>
                <TextField 
                  label="Name" 
                  variant="outlined" 
                  fullWidth 
                  required 
                  margin="normal" 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField 
                  label="Email" 
                  variant="outlined" 
                  fullWidth 
                  required 
                  type="email" 
                  margin="normal" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField 
                  label="Message" 
                  variant="outlined" 
                  fullWidth 
                  required 
                  multiline 
                  rows={4} 
                  margin="normal" 
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                
                {/* File Upload */}
                <Button variant="outlined" component="label" startIcon={<AttachFile />} sx={{ mt: 2 }}>
                  Attach an Image
                  <input type="file" hidden accept="image/*" onChange={handleFileChange} />
                </Button>
                {file && <Typography sx={{ mt: 1 }}>{file.name}</Typography>}
                
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  sx={{ mt: 3 }}
                  onClick={handleSubmitContactUs}
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Contact Info Section */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <IconButton color="primary">
                  <Phone />
                </IconButton>
                <Typography component="a" href="tel:+917989404096" variant="body1" sx={{ ml: 1 }}>
                  +91 7989404096
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <IconButton color="primary">
                  <Email />
                </IconButton>
                <Typography component="a" href="mailto:contact@thirtyseven.in" variant="body1" sx={{ ml: 1 }}>
                  contact@thirtyseven.in
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
