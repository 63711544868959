import React, {useEffect} from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router-dom';

const FAQ = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return (
    <Box sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom>
      General Questions
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Contact Form Section */}
        <Grid item >
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
                <h5>What is ThirtySeven?</h5>
                <ul className="list-unstyled p-2">
                    <li>ThirtySeven is a premium men's clothing brand offering a wide range of stylish and comfortable apparel, including shirts, trousers, jackets, and accessories.</li>
                </ul>
                <h5>Where is ThirtySeven located?</h5>
                <ul className="list-unstyled p-2">
                    <li>We operate exclusively online, making it easy for customers to shop from anywhere.</li>
                </ul>
                <h5>How can I contact customer support?</h5>
                <ul className="list-unstyled p-2">
                    <li>You can reach our customer support team via email at support@thirtyseven.com or through our Contact Us page.</li>
                </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FAQ;
