import React, { useState, useEffect } from 'react';
import { InputAdornment, Box, Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, Card, CardContent, Divider, IconButton, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Menu} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Checkout.css';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiSolidOffer } from "react-icons/bi";
import Loader from "../../components/Loader"; // Import the Loader component
import Cookies from 'js-cookie';
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Swal from 'sweetalert2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CryptoJS from "crypto-js";

function Checkout() {
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState('reviewItems');
  const [showPromo, setShowPromo] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [first10Records, setFirst10Records] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get('sessionToken');
  const SECRET_KEY = "vG8zT2kLmN4pXyA9sB1qW7rZ5dCfJ0Vh";

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);
  const handleSizeClick = (event, bagId) => {
    setAnchorEl((prev) => ({ ...prev, [bagId]: event.currentTarget }));
  };
  const handleMenuClose = (bagId) => {
    setAnchorEl((prev) => ({ ...prev, [bagId]: null }));
  };
  const handleSizeSelect = (selectedSize, bagId) => {
    handleMenuClose(bagId);
    setLoading(true);
    axios.post(baseURL + "/update_bag_size", {
      user_id: JSON.parse(token)['id'],bag_id:bagId.toString(),size:selectedSize
    }).then((res)=>{
      if(res.data.status === 'success'){
        handleRemovePromo()
        if(bagUpdated === false){
          setBagUpdated(true)
        }else{
          setBagUpdated(false)
        }
      }
    })
  };
  const [cartproduct, setCartproduct] = useState([])
  const [isAnyItemSelected, setIsAnyItemSelected] = useState(false)
  const handleCloseAddress = () => setOpen(false);
  const [bagUpdated, setBagUpdated] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalMrp, setTotalMrp] = useState(0)
  const [totalBase, setTotalBase] = useState(0)
  const [tax, setTax] = useState(0)
  const [formDataAddress, setFormDataAddress] = useState({
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      address: '',
      zip: '',
      state: '',
      city: '',
      address_type: '',
      is_default: '',
    });
  const [mode, setMode] = useState('add');
  const [editedId, setEditedId] = useState("");
  const [promocodeDetails, setPromocodeDetails] = useState([])
  const [promoCode, setPromoCode] = useState("")
  const [appliedPromo, setAppliedPromo] = useState("")
  const [promoDiscount, setPromoDiscount] = useState(0)

  
  const confirm = async() => {
    // Function to encrypt data
    function encryptData(data, secretKey) {
      const key = CryptoJS.enc.Utf8.parse(secretKey);
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    }
    setLoading(true)
    // const transactionId = `TXN${Date.now()}`
    if (addresses.length <= 0) {
      Swal.fire({
        icon: 'warning',
        text: 'Please add the shipping address.',
      });
      setLoading(false)
      return false
    }
    const post_data2 = {
      user_id: JSON.parse(token)['id'],
      promoCode: promoCode
    };
    const encryptedData2 = encryptData(post_data2, SECRET_KEY);
    const save_response = await fetch(baseURL +"/save_booking_data", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ encrypted_data: encryptedData2 }),
    });
    const data_save_response = await save_response.json();
    if (data_save_response.status == 'failed') {
      Swal.fire({
        icon: 'warning',
        text: data_save_response.data,
      });
      setLoading(false)
      return false
    }
    if (data_save_response.transactionId !== "") {
      try {
        const post_data = {
          amount: (totalPrice - promoDiscount) * 100,
          transactionId: data_save_response.transactionId
        };
        const encryptedData = encryptData(post_data, SECRET_KEY);
        const response = await fetch(baseURL +"/phonepe_initiate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ encrypted_data: encryptedData }),
        });
        const data = await response.json();
        if (data.success && data.data.instrumentResponse.redirectInfo.url) {
          window.location.href = data.data.instrumentResponse.redirectInfo.url;
        } else {
          setLoading(false)
          Swal.fire({
            icon: 'warning',
            text: 'Transaction failed.',
          });
          return false
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
      // navigate('/OrderConfirmation');
  }
  const handleApplyPromo = () => {
    if (promoCode.trim()) {
      setLoading(true);
      axios.post(baseURL + "/promocode_apply", {
        user_id: JSON.parse(token)['id'],promo_code:promoCode,total_price:totalPrice
      }).then((res)=>{
        setLoading(false);
        if(res.data.status === 'success'){
          setShowPromo(false);
          setAppliedPromo(promoCode)
          setPromoDiscount(parseFloat(res.data.discount_value))
        }else if(res.data.status === 'used'){
          Swal.fire({
            icon: 'warning',
            text: 'This promo code can be used only once per user.',
          });
        }else{
          Swal.fire({
            icon: 'warning',
            text: 'promo code invalid or expired.',
          });
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please enter a valid promo code.',
      });
    }
  };
  const handleRemovePromo = () => {
    setAppliedPromo("")
    setPromoCode("");
    setPromoDiscount(0)
    setShowPromo(false); // Show the promo input field again
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const handleIncrease = (index, prod_id, size) => {
    const maxLimit = parseInt(cartproduct[index].max_limit);
    if (cartproduct[index].cart_count < maxLimit) {
      cartproduct[index].cart_count += 1;
      setLoading(true);
      axios.post(baseURL + "/update_bag_count", {
        user_id: JSON.parse(token)['id'],prod_id:prod_id.toString(),count:cartproduct[index].cart_count,size:size
      }).then((res)=>{
        if(res.data.status === 'success'){
          handleRemovePromo()
          if(bagUpdated === false){
            setBagUpdated(true)
          }else{
            setBagUpdated(false)
          }
        }
      })
    } else {
      Swal.fire({
        icon: 'warning', // Icon type: 'success', 'error', 'warning', 'info', 'question'
        text: 'You have reached the maximum limit for this item.',
      });
    }
  };
  const addToCart = (id) => {
    navigate('/CartPage', { state: { id } });
  }
  const handleDecrease = (index, prod_id, size) => {
    if (cartproduct[index].cart_count > 0) {
      cartproduct[index].cart_count -= 1;
      if(cartproduct[index].cart_count < 1){
        Swal.fire({
          title: 'Are you sure? You want to remove',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
            axios.post(baseURL + "/remove_bag", {
              user_id: JSON.parse(token)['id'],prod_id:prod_id.toString(),size:size
            }).then((res)=>{
              if(res.data.status === 'success'){
                handleRemovePromo()
                if(bagUpdated === false){
                  setBagUpdated(true)
                }else{
                  setBagUpdated(false)
                }
              }
            })
          }else{
            cartproduct[index].cart_count += 1
          }
        })
      }else{
        setLoading(true);
        axios.post(baseURL + "/update_bag_count", {
          user_id: JSON.parse(token)['id'],prod_id:prod_id.toString(),count:cartproduct[index].cart_count,size:size
        }).then((res)=>{
          if(res.data.status === 'success'){
            handleRemovePromo()
            if(bagUpdated === false){
              setBagUpdated(true)
            }else{
              setBagUpdated(false)
            }
          }
        })
      }
    }
  };

  const handleMoveWishlist = (bag_id, prod_id, size) => {
    Swal.fire({
      title: 'Are you sure? You want to move to wishlist.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.post(baseURL + "/update_bag_to_wishlist", {
          user_id: JSON.parse(token)['id'],prod_id:prod_id.toString(),bag_id:bag_id,size:size
        }).then((res)=>{
          if(res.data.status === 'success'){
            handleRemovePromo()
            if(bagUpdated === false){
              setBagUpdated(true)
            }else{
              setBagUpdated(false)
            }
          }
        })
      }
    })
  }

  const handleCheckboxChange = (bag_id, selected_checkout) => {
    if(selected_checkout === "1"){
      selected_checkout = "0"
    }else{
      selected_checkout = "1"
    }
    setLoading(true);
    axios.post(baseURL + "/update_product_checkout", {
      user_id: JSON.parse(token)['id'],bag_id:bag_id,selected_checkout:selected_checkout
    }).then((res)=>{
      if(res.data.status === 'success'){
        handleRemovePromo()
        if(bagUpdated === false){
          setBagUpdated(true)
        }else{
          setBagUpdated(false)
        }
      }
    })
  };

  useEffect(() => {
    const token = Cookies.get('sessionToken');
    const foundUser = JSON.parse(token);
    if(token == undefined){
      Cookies.remove('sessionToken');
      navigate('/');
    }
    setLoading(true);
    axios.post(baseURL + "/get_checkout_details", {"id":foundUser['id'].toString()})
      .then(response => {
        setLoading(false);
        if(response.data.status == "success"){
          setFirst10Records(response.data.first_10_records)
          setCartproduct(response.data.bag_list)
          setAddresses(response.data.address_details)
          if(response.data.total_count > 0){
            setIsAnyItemSelected(true)
          }else{
            setIsAnyItemSelected(false)
          }
          setTotalCount(response.data.total_count)
          setTotalPrice(response.data.total_price)
          setTotalMrp(response.data.total_mrp)
          setTotalBase(response.data.total_base)
          setTax(response.data.tax)
          setPromocodeDetails(response.data.promocode_details)
        }
      })
  },[bagUpdated])
 
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };  
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const handleOpenEditAddress = (id) => {
    setLoading(true);
    axios.post(baseURL + "/get_single_address_details", {"id":id}).then((response) => {
      if(response.data.status === 'success'){
        setFormDataAddress(response.data.data);
        setEditedId(id.toString())
        setOpen(true);
        setMode('edit')
        setLoading(false);
      }else{
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
      }
    })
  }
  const handleOpenNewAddress = () => {
    setLoading(true);
    axios.post(baseURL + "/get_user_details", {"id":JSON.parse(token)['id']}).then((response) => {
      if(response.data.status === 'success'){
        setFormDataAddress({
          contact_name: response.data.data.name,
          contact_phone: response.data.data.phone,
          contact_email: response.data.data.email
        })
        setMode('add');
        setOpen(true);
        setLoading(false);
      }else{
        navigate('/');
        setLoading(false);
      }
    })
  }
  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setFormDataAddress({ ...formDataAddress, [name]: value });
  };
  const handleSubmitAddress = (e) => {
    e.preventDefault();
    setLoading(true)
    
    if(!formDataAddress.contact_name || formDataAddress.contact_name === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter contact name',
      });
      setLoading(false)
      return false;
    }
    if(!formDataAddress.contact_phone || formDataAddress.contact_phone === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter contact phone',
      });
      setLoading(false)
      return false;
    }
    if(!formDataAddress.address || formDataAddress.address === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter address',
      });
      setLoading(false)
      return false;
    }
    if(!formDataAddress.zip || formDataAddress.zip === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter zip',
      });
      setLoading(false)
      return false;
    }
    if(!formDataAddress.state || formDataAddress.state === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter state',
      });
      setLoading(false)
      return false;
    }
    if(!formDataAddress.city || formDataAddress.city === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter city',
      });
      setLoading(false)
      return false;
    }
    if(!formDataAddress.address_type || formDataAddress.address_type === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter address type',
      });
      setLoading(false)
      return false;
    }
    axios.post(baseURL + "/update_user_address", {"id":JSON.parse(token)['id'],"formData":formDataAddress,"mode":mode,"editedId":editedId}).then((response) => {
      if(response.data.status === 'success'){
        Swal.fire({
          icon: 'success',
          text: 'Address updated successfully.',
        });
        if(bagUpdated === false){
          setBagUpdated(true)
        }else{
          setBagUpdated(false)
        }
        setMode('');
        setOpen(false);
        return false;
      }else if(response.data.status === 'duplicate'){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An address with this type already exists. Please edit the existing address or select a different address type.',
        });
        setLoading(false)
        return false;
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
        setLoading(false)
        return false;
      }
    })
  }
  const handleSelectedAddress = (id) => {
    setLoading(true);
    axios.post(baseURL + "/set_default_address", {"id":id,"user_id":JSON.parse(token)['id']}).then((response) => {
      if(response.data.status === 'success'){
        if(bagUpdated === false){
          setBagUpdated(true)
        }else{
          setBagUpdated(false)
        }
      }else{
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
      }
    })
  }
  return (
    <div className='p-4'>
      {loading ? <Loader /> : ""}
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={2}>
        
        {/* Left side - Steps */}
        <Box flex={1}>
          {/* Step 1: Review items */}
          <Accordion expanded="true" onChange={handleChange('reviewItems')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="reviewItems-content" id="reviewItems-header">
              <Typography variant="h6">Review items</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {/* Address List */}
                {cartproduct?.length > 0 && cartproduct.map((item, index) => (
                  <Box
                    key={item.id}
                    className="checkout-product-mobile"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={2}
                    mb={2}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.selected_checkout === "1"}
                          onChange={() => handleCheckboxChange(item.bag_id, item.selected_checkout)}
                          color="primary"
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center">
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            <LazyLoadImage src={item.images} alt="Image description" className="checkout-cart-image" effect="blur"/>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {item.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.description}
                          </Typography>
                          <Typography variant="body2" className='' color="textSecondary" display="flex" alignItems="center" gap={1}>
                            <lable style={{color:"black",fontWeight:"bold"}}>₹{item.sell_price}</lable>
                            <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{item.mrp}</lable>
                            <lable style={{color:"green"}}>{item.discount}% OFF</lable>
                          </Typography>
                        </Box>
                      </Box>
                      }
                    />
                      <Box className="checkout-product-qty" gap={{ xs: 4, sm: 30}} sx={{ textAlign: 'center', padding: '10px' }}>
                        {/* Size Dropdown */}
                        <Button
                          onClick={(event) => handleSizeClick(event, item.bag_id)}
                          sx={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'none',
                            padding: 0,
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                        >
                          <span style={{fontWeight:"normal"}}>Size:</span> {item.cart_size}
                        </Button>
                        <Menu
                          anchorEl={anchorEl[item.bag_id] || null}
                          open={Boolean(anchorEl[item.bag_id])}
                          onClose={() => handleMenuClose(item.bag_id)}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {item.stock_available.map((option) => (
                            <MenuItem
                              key={option}
                              onClick={() => handleSizeSelect(option, item.bag_id)}
                              sx={{ fontSize: '16px' }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>

                        {/* Quantity Selector */}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '10px',
                            border: '1px solid #000',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            width: 'fit-content',
                            backgroundColor: '#fff',
                          }}
                        >
                          <IconButton
                            onClick={() => handleDecrease(index, item.prod_id, item.cart_size)}
                            sx={{
                              borderRadius: 0,
                              padding: '4px 8px', // Smaller padding
                              backgroundColor: '#000',
                              color: '#fff',
                              '&:hover': { backgroundColor: '#333' },
                            }}
                          >
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                          <Typography
                            variant="body2" // Smaller font size
                            sx={{
                              width: '32px', // Reduced width
                              textAlign: 'center',
                              fontWeight: 'bold',
                              color: '#000',
                            }}
                          >
                            {item.cart_count}
                          </Typography>
                          <IconButton
                            onClick={() => handleIncrease(index, item.prod_id, item.cart_size)}
                            sx={{
                              borderRadius: 0,
                              padding: '4px 8px',
                              backgroundColor: '#000',
                              color: '#fff',
                              '&:hover': { backgroundColor: '#333' },
                            }}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                      <Typography variant="body2" className='checkout-product-qty' color="textSecondary">
                        <Button variant="outlined" onClick={() => handleMoveWishlist(item.bag_id, item.prod_id, item.cart_size)}>Move to Whishlist</Button>
                      </Typography>
                  </Box>
                ))}

              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Step 2: Shipping Address */}
          <Accordion expanded="true" onChange={handleChange('shippingAddress')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="shippingAddress-content" id="shippingAddress-header">
              <Typography variant="h6">Shipping Address</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {/* Address List */}
                {addresses.map((item) => (
                  <Box
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={2}
                    mb={2}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.is_default === "1"}
                          onChange={() => handleSelectedAddress(item.id)}
                          color="primary"
                        />
                      }
                      label={
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {item.type}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.address}
                          </Typography>
                        </Box>
                      }
                    />
                    <IconButton color="primary" onClick={(e) => handleOpenEditAddress(item.id)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                ))}

                {/* Add New Address Button */}
                <Button variant="outlined" color="primary" fullWidth onClick={handleOpenNewAddress} size="large">
                  Add New Address
                </Button>

                {/* New Address Dialog (Popup) */}
                <Dialog className='profile_address_popup' open={open} onClose={handleCloseAddress} maxWidth="sm" fullWidth>
                  <DialogTitle>Add New Address</DialogTitle>
                  <DialogContent>
                    <Box component="form" mt={1} noValidate autoComplete="off">
                      <Box display="flex" gap={2} mb={2}>
                        <TextField label="Contact Name" name="contact_name" variant="outlined" fullWidth required value={formDataAddress.contact_name} onChange={handleChangeAddress}/>
                      </Box>
                      <TextField label="Contact Phone Number" name="contact_phone" variant="outlined" fullWidth required value={formDataAddress.contact_phone} onChange={handleChangeAddress} sx={{ mb: 2 }} InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +91
                          </InputAdornment>
                        ),
                        inputProps: { maxLength: 10 }, // Limit phone number length if needed
                      }}/>
                      <TextField label="Street Address" name="address" variant="outlined" fullWidth required sx={{ mb: 2 }} value={formDataAddress.address} onChange={handleChangeAddress}/>
                      <Box display="flex" gap={2} mb={2}>
                      <TextField label="Zip Code" name="zip" variant="outlined" fullWidth required value={formDataAddress.zip} onChange={handleChangeAddress}/>
                      </Box>
                      <Box display="flex" gap={2} mb={2}>
                        <TextField
                          label="State"
                          variant="outlined"
                          name="state"
                          select
                          SelectProps={{ native: true }}
                          fullWidth
                          value={formDataAddress.state}
                          onChange={handleChangeAddress}
                          required
                        >
                          <option value="">Select State</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">Himachal Pradesh</option>
                          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          {/* Add more states as needed */}
                        </TextField>
                        <TextField label="City" name="city" variant="outlined" fullWidth required value={formDataAddress.city} onChange={handleChangeAddress}/>
                      </Box>
                      <Box display="flex" gap={2} mb={2}>
                        <TextField label="Type Of Address" name="address_type" placeholder='Ex. Home,Office' variant="outlined" fullWidth value={formDataAddress.address_type} onChange={handleChangeAddress}/>
                      </Box>
                      <Box display="flex" gap={2} mb={2}>
                      <TextField label="Contact Email" name="contact_email" variant="outlined" fullWidth value={formDataAddress.contact_email} onChange={handleChangeAddress}/>
                      </Box>
                      <Box display="flex" gap={2} mb={2}>
                        <FormControlLabel control={<Checkbox name="is_default" defaultChecked checked={formDataAddress.is_default} onChange={handleChangeAddress}/>} label="Make this as my default address" />
                      </Box>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseAddress} color="secondary">Cancel</Button>
                    <Button onClick={handleSubmitAddress} variant="contained" color="primary">
                      Save Address
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Step 3: Offers */}
          <Accordion expanded={expanded === 'Offers'} onChange={handleChange('Offers')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Offers-content" id="Offers-header">
              <Typography variant="h6">Offers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <ul className="list-unstyled mt-3">
                  {promocodeDetails?.length > 0 && promocodeDetails.map((point) => (
                    <>
                      <li><BiSolidOffer size={20} color='dodgerblue'/> {point.title} Use Code: <b>{point.code}</b></li>
                      <li style={{marginLeft:20}} className='mb-3'>{point.description}</li>
                    </>
                  ))}
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          {/* Step 4: Terms and Conditions */}
          <Accordion expanded={expanded === 'termsConditions'} onChange={handleChange('termsConditions')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="termsConditions-content" id="termsConditions-header">
              <Typography variant="h6">Terms and Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Accordion>
                  <Box sx={{ marginBottom: '20px' }} padding={2}>
                    <Typography variant="h6" gutterBottom>General Information</Typography>
                    <Typography variant="body1">
                      Welcome to ThirtySeven. By accessing or using our website, you agree to comply with and be bound by the
                      following terms and conditions. Please read these terms carefully before using our website.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Product Information</Typography>
                    <Typography variant="body1">
                      We strive to display our products as accurately as possible. However, colors, sizes, and product images
                      may vary due to screen display differences.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Pricing and Payments</Typography>
                    <Typography variant="body1">
                      Prices are in Indian Rupees (INR) and include applicable taxes. Orders above Rs.1000 qualify for free
                      shipping. Payment methods include credit/debit cards, UPI, and other secure options provided on checkout.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Shipping Policy</Typography>
                    <Typography variant="body1">
                      We offer free shipping on orders above Rs.1000. Orders are processed within 2-3 business days and will be
                      delivered within 5-7 days depending on your location.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Limitation of Liability</Typography>
                    <Typography variant="body1">
                      ThirtySeven is not liable for any indirect or consequential damages arising from the use of our website or
                      services.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Changes to Terms</Typography>
                    <Typography variant="body1">
                      We reserve the right to update these terms at any time without prior notice. Changes will be effective
                      immediately upon posting to the website.
                    </Typography>
                  </Box>
                </Accordion>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Step 5: Cancellation and Return Policy */}
          <Accordion expanded={expanded === 'CancellationReturn'} onChange={handleChange('CancellationReturn')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="CancellationReturn-content" id="CancellationReturn-header">
              <Typography variant="h6">Cancellation and Return Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
              <Accordion>
                  <Box sx={{ marginBottom: '20px' }} padding={2}>
                    <Typography variant="h6" gutterBottom>Return Eligibility</Typography>
                    <Typography variant="body1">
                      You may return items within 7 days of delivery. To be eligible for a return, items must be unworn, unwashed, with all tags attached, and in their original packaging
                    </Typography>
                    <Typography variant="h6" gutterBottom>Return Process</Typography>
                    <Typography variant="body1">
                      To initiate a return, please contact our customer support team with your order details. We will guide you through the process, and you may be required to ship the items back to us.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Refund Policy</Typography>
                    <Typography variant="body1">
                      Once we receive and inspect your return, we will process your refund within 5-7 business days. Refunds will be issued to the original payment method.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Exchange Policy</Typography>
                    <Typography variant="body1">
                    Exchanges are available within 7 days for items that meet the return eligibility criteria. Contact our customer support to initiate an exchange.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Order Cancellation</Typography>
                    <Typography variant="body1">
                      Orders can be canceled within 24 hours of placement. Once an order is processed, it cannot be canceled.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Non-Returnable Items</Typography>
                    <Typography variant="body1">
                    Some items may not be eligible for return due to hygiene reasons (e.g., innerwear). Please check the product details for return eligibility.
                    </Typography>
                  </Box>
                </Accordion>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Right side - Order Summary */}
        <Box flex={{ xs: 1, lg: 0.4 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>Order Summary</Typography>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>MRP Total</Typography>
                <Typography>₹ {totalMrp.toFixed(2)}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Discount on MRP</Typography>
                <Typography color="green">-₹ {(totalMrp-totalPrice).toFixed(2)}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Subtotal ({totalCount} items)</Typography>
                <Typography>₹ {totalPrice.toFixed(2)}</Typography>
              </Box>
              {/* <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Taxes(+{tax}%)</Typography>
                <Typography>₹ {(totalPrice - totalBase).toFixed(2)}</Typography>
              </Box> */}
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Shipping</Typography>
                <Typography color="error"><label className='line-through' style={{ fontSize: '13px',color:"#b9b9b9" }}>₹ 100 </label> Free</Typography>
              </Box>
              {appliedPromo && (
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography>Promo Discount</Typography>
                  <Typography color="green">-₹ {(promoDiscount).toFixed(2)}</Typography>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6">To Pay</Typography>
                <Typography variant="h6">₹ {(totalPrice-promoDiscount).toFixed(2)}</Typography>
              </Box>

              {/* Promo Code */}
              {!appliedPromo && (
                <Button color="primary" onClick={() => setShowPromo(!showPromo)} sx={{ mb: 1 }}>
                  Have a promo code?
                </Button>
              )}
              {showPromo && !appliedPromo && (
                <TextField
                placeholder="Enter promo code"
                variant="outlined"
                fullWidth
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="contained" color="secondary" size="large" onClick={handleApplyPromo}>
                        Apply
                      </Button>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1 }}
              />
              )}
              {appliedPromo && (
                <div>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Typography>Promo code applied: <strong>{appliedPromo}</strong></Typography>
                    <Typography><Button variant="outlined" color="error" onClick={handleRemovePromo}>Remove</Button></Typography>
                  </Box>
                </div>
              )}
              <Button variant="contained" color="primary" size="large" onClick={confirm} fullWidth disabled={!isAnyItemSelected}>Buy Now</Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      
      <div className="suggestions m-3 pt-5">
        <h3>Add More</h3>
        <div className="">
          <Slider {...settings2}>
            {first10Records?.length > 0 && first10Records.map((each) => (
              <div className="image-text-container" onClick={(e) => addToCart(each['_id'].toString())}>
                <LazyLoadImage src={`https://admin.thirtyseven.in/assets/images/product/${each['images'][0]}`} alt="Image description" className="zoom-image" effect="blur"/>
                <div className="text-content">
                  <h6 className='mobile-product-name'>{each['name']}</h6>
                  <span className='d-flex gap-1 mobile-price-font'>
                    <lable style={{color:"black"}}>₹{each['sell_price']}</lable>
                    <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{each['mrp']}</lable>
                    <lable style={{color:"green"}}>{each['discount']}% OFF</lable>
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
