import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import WishlistItem from "../../components/WishlistItem";
import Loader from "../../components/Loader"; // Import the Loader component
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';

const Wishlist = ({ setSelectedWishlistCount }) => {
  const { pathname } = useLocation();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = Cookies.get('sessionToken');
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if(token == undefined){
      return false;
    }
    axios.post(baseURL + "/get_wishlist_details", {
      user_id: JSON.parse(token)['id']
    }).then((res)=>{
      if(res.data.status === 'success'){
        setLoading(false);
        setWishlistItems(res.data.products)
      }else{
        setLoading(false);
      }
    })
  }, []);

  const handleRemove = (id) => {
    Swal.fire({
      title: 'Are you sure? You want to remove',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.post(baseURL + "/remove_wishlist", {
          user_id: JSON.parse(token)['id'],prod_id:id.toString()
        }).then((res)=>{
          if(res.data.status === 'success'){
            setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== id));
            setSelectedWishlistCount('0');
            setLoading(false);
          }
        })
      }
    })
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  const handleAddToBag = (id) => {
    const fromWishlist = "yes";
    navigate('/CartPage', { state: { id, fromWishlist } });
  };

  const handleAddAllToBag = () => {
    // Logic to add all items to the shopping bag
    console.log("Added all items to shopping bag.");
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed
  }, []);

  return (
    <Container maxWidth="md">
      {loading ? <Loader /> : ""}
      <Typography variant="h4" gutterBottom>
        Your Wishlist
      </Typography>
      {wishlistItems.length === 0 ? (
        <Typography variant="body1" color="text.secondary">
          Your wishlist is empty.
        </Typography>
      ) : (
        <>
          {wishlistItems.map((item) => (
            <WishlistItem key={item.id} item={item} onRemove={handleRemove} onAddToBag={handleAddToBag} />
          ))}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="body1" gutterBottom>
              Do you want to purchase your entire wishlist?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled
              onClick={handleAddAllToBag}
              sx={{ mt: 1 }}
            >
              Add all to Shopping Bag
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Wishlist;
