import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-awesome-slider/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CartPage.css';
import measurement from '../../img/measurement.jpg'
import { Modal, Table, Tab, Nav } from 'react-bootstrap';
import { TbRulerMeasure, TbTruckDelivery } from "react-icons/tb";
import { IoBagCheckOutline } from "react-icons/io5";
import { BiSolidOffer } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Loader from "../../components/Loader"; // Import the Loader component
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Cookies from 'js-cookie';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AuthModal from '../../components/AuthModal';
import Swal from 'sweetalert2';

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      onClick={onClick}
    >
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      onClick={onClick}
    >
    </div>
  );
};  
const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5, // Desktop
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024, // Tablet view
      settings: {
        slidesToShow: 3, // Adjust for tablet screens
      },
    },
    {
      breakpoint: 768, // Mobile
      settings: {
        slidesToShow: 2,
      },
    },
  ],
  autoplay: false,
  autoplaySpeed: 3000, // Adjust autoplay speed as needed
  lazyLoad: 'ondemand',
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
const AddToCartPage = ({setSelectedBagCount}) => {
  const [images, setImages] = useState([]);
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [size, setSize] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [availability, setAvailability] = useState(null);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState(new Array(images.length).fill(false));
  const [product, setProduct] = useState({sizes: []});
  const { id } = location.state || "";
  const { fromWishlist } = location.state || "";
  const [productDetails, setProductDetails] = useState(null);
  const [first10Records, setFirst10Records] = useState([]);
  const token = Cookies.get('sessionToken');
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [openFrom, setOpenFrom] = useState("");
  // const [pId, setPId] = useState(false);
  const [promocodeDetails, setPromocodeDetails] = useState([])

  const handleCloseSizeChart = () => setShowSizeChart(false);
  const handleShowSizeChart = () => setShowSizeChart(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(id != ""){
      setLoading(true);
      axios.post(baseURL + "/get_cartpage_details", {"id":id.toString()})
        .then(response => {
          if(response.data.status == "success"){
            setImages(response.data.data.images)
            setProduct(response.data.data)
            const formattedDetails = {
              "Pack of": "1",
              "Style Code": response.data.data.product_id || "",
              "Fit": response.data.data.fit || "",
              "Fabric": response.data.data.fabric || "",
              "Sleeve": response.data.data.sleeve || "",
              "Pattern": response.data.data.pattern || "",
              "Reversible": response.data.data.reversible || "No",
              "Occasion": response.data.data.occasion || "",
              "Color": response.data.data.color || "",
              "Fabric Care": "Machine wash as per tag",
              "Sale": response.data.data.sale || "Men",
            };
            setProductDetails(formattedDetails);
            setFirst10Records(response.data.extra_product)
            setPromocodeDetails(response.data.promocode_details)
            setLoading(false);
          }else{
            setLoading(false);
          }
      })
    }else{
      navigate('/ProductList');
    }
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);
  const checkAvailability = () => {
    setLoading(true);
    axios.post(baseURL + "/check_delivery_availability", {"post_code":zipCode}).then(response => {
      if(response.data.status == "success"){
        setAvailability("✅ Delivery Available! Estimated delivery date: "+response.data.serviceability_data.etd);
        setLoading(false);
      }else{
        setLoading(false);
        setAvailability(false);
      }
    })
  };  
  const checkout = () => {
    if(token !== undefined){
      navigate('/Checkout');
    }else{
      setIsModalOpen(true);
    }
  }
  useEffect(() => {
    images.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setLoadedImages((prev) => {
          const newLoadedImages = [...prev];
          newLoadedImages[index] = true;
          return newLoadedImages;
        });
      };
    });
  }, [images]);
  const addToBag = async (productId) => {
    if(token !== undefined){
      setLoading(true)
      if(size === ""){
        Swal.fire({
          icon: 'error', // Icon type: 'success', 'error', 'warning', 'info', 'question'
          title: 'Oops...',
          text: 'Please select size',
        });
        setLoading(false)
        return false;
      }
      const foundUser = JSON.parse(token);
      const response = await axios.post(baseURL + "/add_to_bag", {
        prod_id: productId.toString(), user_id: foundUser['id'],size:size,zip_code:zipCode,fromWishlist:fromWishlist
      });
      if(response.data.status === "success"){
        setSelectedBagCount("1")
        Swal.fire({
          title: "What would you like to do?",
          text: "Choose an action to proceed",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Checkout",
          cancelButtonText: "Continue Shopping",
          reverseButtons: true, // Makes the cancel button appear first
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/Checkout');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate('/ProductList');
          }
        });
      }
      setLoading(false)
    }else{
      // setOpenFrom("cart");
      // setPId(productId)
      setIsModalOpen(true);
    }
  }
  const handleLoginSuccess = (pId) => {
    // addToBag(pId);
  };
  const closeModal = () => setIsModalOpen(false);
  const openLightbox = (index) => {
    const img = new Image();
    img.src = images[index];
    img.onload = () => {
      setPhotoIndex(index);
      setIsOpen(true);
    };
  };
  return (
    <>
    {loading ? <Loader /> : ""}
    <div className="container mt-1">
      <div className="row">
        <div className="col-md-6">
          <div style={{ textAlign: 'center' }}>
            {/* Main image with click to open Lightbox */}
            <div>
              <img
                src={images[photoIndex]}
                alt="Main display"
                onClick={() => openLightbox(photoIndex)}
                className='main-image'
              />
            </div>

            {/* Thumbnails */}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => setPhotoIndex(index)}
                  style={{
                    width: '80px',
                    height: '80px',
                    cursor: 'pointer',
                    border: index === photoIndex ? '2px solid blue' : 'none',
                    opacity: loadedImages[index] ? 1 : 0.5, // Dim thumbnail if not loaded
                  }}
                />
              ))}
            </div>

            {/* Lightbox for zoomable view */}
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
              />
            )}
          </div>
        </div>

        <div className="col-md-6">
          <h1>{product.name}</h1>
          <p>{product.description}</p>
          <span className='d-flex gap-1 mobile-price-font text-larger'>
            <lable style={{color:"black",fontWeight:"bold"}}>₹{product.sell_price}</lable>
            <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "16px",display:"flex",alignItems: "flex-end"}}>₹{product.mrp}</lable>
            <lable style={{color:"green"}}>{product.discount}% Off</lable>
          </span>
          <p className="text-muted">(incl. of all taxes)</p>
          <ul className="list-unstyled mt-3">
            {promocodeDetails?.length > 0 && promocodeDetails.map((point) => (
              <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> {point.title} Use Code: <b>{point.code}</b></li>
            ))}
          </ul>
          {/* Size Selection */}
          <div className="d-flex align-items-center gap-3 mt-3">
            <label className="fw-bold">Select Size</label>
            <div className="d-flex gap-2">
              {product?.sizes?.length > 0 && product.sizes.map((sz) => (
                <div key={sz.size} className="text-center">
                  <button
                    className={`btn btn-outline-secondary rounded-circle px-3 py-2 ${
                      size === sz.size ? 'border-primary bg-primary text-white' : ''
                    } ${ sz.stock == 0 ? 'line-through' : '' }`}
                    disabled={sz.stock == 0}
                    onClick={() => setSize(sz.size)}
                    style={{ minWidth: '45px' }}
                  >
                    {sz.size}
                  </button>
                  {/* {sz.stock != 0 &&
                    <small className="small">{sz.stock} left</small>
                  } */}
                </div>
              ))}
            </div>
          </div>
          <div>
            <h6  onClick={handleShowSizeChart} className='size-chart mt-1'><TbRulerMeasure size={25}/> Size Chart</h6>
            {/* Modal for Size Chart */}
            <Modal show={showSizeChart} onHide={handleCloseSizeChart} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>Size Charts</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Tabs for Size Chart and How to Measure */}
                <Tab.Container defaultActiveKey="sizeChart">
                  <Nav variant="tabs" className="mb-2">
                    <Nav.Item>
                      <Nav.Link eventKey="sizeChart">Shirt Size Chart</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="howToMeasure">How to Measure</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    {/* Size Chart Tab */}
                    <Tab.Pane eventKey="sizeChart">
                      <Table bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Size</th>
                            <th>Brand Size</th>
                            <th>Chest (in)</th>
                            <th>Front Length (in)</th>
                            <th>Across Shoulder (in)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>39</td>
                            <td>S</td>
                            <td>40.0</td>
                            <td>29.0</td>
                            <td>17.3</td>
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>M</td>
                            <td>42.0</td>
                            <td>29.5</td>
                            <td>18.0</td>
                          </tr>
                          <tr>
                            <td>42</td>
                            <td>L</td>
                            <td>44.0</td>
                            <td>30.0</td>
                            <td>18.8</td>
                          </tr>
                          <tr>
                            <td>44</td>
                            <td>XL</td>
                            <td>46.0</td>
                            <td>30.5</td>
                            <td>19.5</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab.Pane>

                    {/* How to Measure Tab */}
                    <Tab.Pane eventKey="howToMeasure">
                      <ul className='text-center'>
                        <img src={measurement} />
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Modal.Body>
            </Modal>
          </div>

          {/* Pincode Checker */}
          <div className="mt-4">
            <label className="fw-bold mb-2">Delivery Info <TbTruckDelivery /></label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter pincode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
              <button className="btn btn-outline-secondary delivery-check" type="button" onClick={checkAvailability}>
                Check
              </button>
            </div>
            {availability !== null && (
              <p className={`mt-2 ${availability ? 'text-success' : 'text-danger'}`}>
                {availability
                  ? availability
                  : 'Delivery not available in this area.'}
              </p>
            )}
          </div>
          <div className="d-flex gap-3 mt-4">
            {/* <button className="btn btn-success btn-lg flex-grow-1" onClick={(e) => addToBag(product._id.toString())}>Add to Bag</button>
            <button className="btn btn-danger btn-lg" onClick={checkout}><IoBagCheckOutline size={20} className='mb-1'/> Checkout</button> */}
            <button className="btn btn-secondary btn-lg">Currently unavailable</button>
          </div>
          <hr />
          <ul className="list-unstyled mt-3">
            {product?.feature?.length > 0 && product.feature.map((point) => (
              <li>✓ { point}</li>
            ))}
          </ul>
          <hr />
          <div className="product-details">
            <h3>Product Details</h3>
            <ul>
              {productDetails && Object.entries(productDetails).map(([key, value]) => (
                <li key={key}>
                  <strong>{key}:</strong> {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      </div>
      <div className="suggestions m-3">
        <h3>You May Also Like</h3>
        <div className="">
          <Slider {...settings2}>
            {first10Records?.length > 0 && first10Records.map((each) => (
              <div className="image-text-container">
                <LazyLoadImage src={`https://admin.thirtyseven.in/assets/images/product/${each['images'][0]}`} alt="Image description" className="zoom-image" effect="blur"/>
                <div className="text-content">
                  <h6 className='mobile-product-name'>{each['name']}</h6>
                  <span className='d-flex gap-1 mobile-price-font'>
                    <lable style={{color:"black"}}>₹{each['sell_price']}</lable>
                    <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{each['mrp']}</lable>
                    <lable style={{color:"green"}}>{each['discount']}% OFF</lable>
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <AuthModal show={isModalOpen} onClose={closeModal} onLoginSuccess={handleLoginSuccess} />
    </>
  );
};

export default AddToCartPage;
