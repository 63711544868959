// ProfilePage.js
import React, { useState, useEffect} from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Tab, Tabs, Box, Typography, Button, Grid, Card, CardHeader, IconButton, Collapse, CardContent, Alert, CardActions, Divider, TextField, DialogActions, Checkbox, FormControlLabel, DialogContent, Dialog, DialogTitle, Avatar, InputAdornment } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { AccountCircle, History, LocationOn, Loyalty, GroupAdd, Logout, Cancel, KeyboardReturnOutlined, ExpandMore, CheckCircle, CancelOutlined, LocalShipping, Edit, Redeem, Star, PersonAdd, Share, FileCopy, Download, Crop32 } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProfilePage.css';
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader"; // Import the Loader component
import Cookies from 'js-cookie';
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Swal from 'sweetalert2';

function ProfilePage() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = Cookies.get('sessionToken');
    if(token == undefined){
      Cookies.remove('sessionToken');
      navigate('/');
    }
  },[])

  // Handlers for switching tabs
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure? You want to logout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const token = Cookies.get('sessionToken');
        const foundUser = JSON.parse(token);
        axios.post(baseURL + "/user_logout", {"id":foundUser['id']})
        .then(response => {
          if(response.data.status == "success"){
            Cookies.remove('sessionToken');
            window.location.href = "https://thirtyseven.in/";
            setLoading(false);
          }else{
            setLoading(false);
          }
        })
      }
    })
  }
  
  return (
    <Container fluid className="p-3" style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      {loading ? <Loader /> : ""}
      <Row>
        <Col lg={3} md={4} className="mb-4">
          {/* Sidebar Navigation */}
          <div className="profile-sidebar p-3 bg-white rounded shadow">
            <h5 className="text-center mb-4">My Profile</h5>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Button
                  startIcon={<AccountCircle />}
                  onClick={() => setSelectedTab(0)}
                  fullWidth
                  color={selectedTab === 0 ? 'primary' : 'inherit'}
                >
                  Account Details
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<History />}
                  onClick={() => setSelectedTab(1)}
                  fullWidth
                  color={selectedTab === 1 ? 'primary' : 'inherit'}
                >
                  Order History
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<LocationOn />}
                  onClick={() => setSelectedTab(2)}
                  fullWidth
                  color={selectedTab === 2 ? 'primary' : 'inherit'}
                >
                  Manage Addresses
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<Loyalty />}
                  onClick={() => setSelectedTab(3)}
                  fullWidth
                  color={selectedTab === 3 ? 'primary' : 'inherit'}
                >
                  Reward Points
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<GroupAdd />}
                  onClick={() => setSelectedTab(4)}
                  fullWidth
                  color={selectedTab === 4 ? 'primary' : 'inherit'}
                >
                  Refer a friend
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<Logout />}
                  onClick={handleLogout}
                  fullWidth
                  color='error'
                >
                  Logout
                </Button>
              </Nav.Item>
            </Nav>
          </div>
        </Col>

        <Col lg={9} md={8}>
          <Box className="bg-white p-4 rounded shadow">
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
              <Tab label="Account Details" />
              <Tab label="Order History" />
              <Tab label="Manage Addresses" />
              <Tab label="Reward Points" />
              <Tab label="Refer a friend" />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              <AccountDetails />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <OrderHistory />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <ManageAddresses />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <Reward />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <Refer />
            </TabPanel>
          </Box>
        </Col>
      </Row>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Account Details Component
function AccountDetails() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    dob: '',
  });
  const [loading, setLoading] = useState(false);
  const token = Cookies.get('sessionToken');
  
  useEffect(() => {
    setLoading(true);
    axios.post(baseURL + "/get_user_details", {"id":JSON.parse(token)['id']}).then((response) => {
      if(response.data.status === 'success'){
        setFormData({
          name: response.data.data.name,
          phone: response.data.data.phone,
          email: response.data.data.email,
          dob: response.data.data.dob,
        })
        setLoading(false);
      }else{
        navigate('/');
        setLoading(false);
      }
    })
  },[])

  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    
    if(formData.name === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter Name',
      });
      setLoading(false)
      return false;
    }
    if(formData.phone === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter phone',
      });
      setLoading(false)
      return false;
    }
    if(formData.email === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter email',
      });
      setLoading(false)
      return false;
    }
    if(formData.dob === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter Date of birth',
      });
      setLoading(false)
      return false;
    }
    axios.post(baseURL + "/update_user_details", {"id":JSON.parse(token)['id'],"formData":formData}).then((response) => {
      if(response.data.status === 'success'){
        Swal.fire({
          icon: 'success',
          text: 'Details updated successfully.',
        });
        setLoading(false)
        setIsEditing(false);
        return false;
      }else if(response.data.status === 'dup_email'){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Email-id already register',
        });
        setLoading(false)
        return false;
      }else if(response.data.status === 'dup_phone'){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Phone Number already register',
        });
        setLoading(false)
        return false;
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
        setLoading(false)
        return false;
      }
    })
  };

  return (
    <Container maxWidth="sm" >
      {loading ? <Loader /> : ""}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              disabled={!isEditing}
              required
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              disabled={!isEditing}
              required
              type="tel"
              inputProps={{ maxLength: 10 }}
              helperText="Enter 10-digit phone number"
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={!isEditing}
              required
              type="email"
            />
          </Grid>

          {/* Date of Birth */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Date of Birth"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              disabled={!isEditing}
              required
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          {isEditing ? (
            <>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          ) : (""
          )}
        </Box>
      </Box>
      {!isEditing ? (
          <Button
              type='button'
              variant="contained"
              color="primary"
              onClick={handleEdit}
            >
              Edit
            </Button>
      ):("")}
    </Container>
  );
}

// Order History Component
function OrderHistory() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [expandedOrderIds, setExpandedOrderIds] = useState([]); // Tracks expanded cards
  const token = Cookies.get('sessionToken');
  const [loading, setLoading] = useState(false);

  const toggleExpand = (orderId) => {
    setExpandedOrderIds((prev) =>
      prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
    );
  };
  
  useEffect(() => {
    setLoading(true);
    axios.post(baseURL + "/get_order_details", {"id":btoa(JSON.parse(token)['id'])}).then((response) => {
      if(response.data.status === 'success'){
        setOrders(response.data.data);
        setLoading(false);
      }else{
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
      }
    })
  }, []);
  const handleDownloadInvoice = (filename) => {
    const fileUrl = `${baseURL}/download_invoice/${filename}.pdf`;
  
    // Create a temporary link element to trigger the download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", filename); // Suggest a filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleReturnExchange = (id) => {
    navigate('/return_exchange', { state: { id } });
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // dd-mm-yyyy format
  };
  const formatISTDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
        timeZone: 'Asia/Kolkata', // IST timezone
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    }).replace(',', '');
  };
  const cancelOrder = async (orderId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to cancel this order? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Cancel It!",
    });
    if (result.isConfirmed) {
      setLoading(true);
      axios.post(baseURL + "/cancel_order", {"id":btoa(JSON.parse(token)['id']),"oder_id":btoa(orderId)}).then((response) => {
        if(response.data.status === 'success'){
          setLoading(false);
          window.location.reload();
        }else{
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'something went wrong, Please try agian!',
          });
        }
      })
    }
  }
  return (
    <Box>
      {loading ? <Loader /> : ""}
      {orders.map((order,index) => (
        <Card key={order.id} sx={{ marginBottom: 2, boxShadow: 1 }}>
          <CardHeader
            title={`Order ID: #${order.id}`}
            action={
              <IconButton onClick={() => toggleExpand(order.id)} color="info">
                <ExpandMore
                  sx={{
                    transform: expandedOrderIds.includes(order.id) ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease'
                  }}
                />
              </IconButton>
            }
          />
          <Collapse in={expandedOrderIds.includes(order.id)} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Order Date:</strong> {formatISTDate(order.date)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Status:</strong> {order.status}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Estimated Delivery:</strong> {order.estimatedDelivery}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Shipping Address:</strong> {order.address}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Payment Method:</strong> {order.paymentMethod}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Total Amount:</strong> &#8377; {order.totalAmount}</Typography>
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>Items</Typography>
              {order.items.map((item, index) => (
                <Grid container key={index} sx={{ borderBottom: '1px solid #ddd', paddingY: 1 }}>
                  <Grid item xs={8}>
                    <Typography variant="body2">{item.name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">Qty: {item.qty}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">&#8377; {item.price}</Typography>
                  </Grid>
                </Grid>
              ))}
                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">Order Tracking</Typography>
                <Timeline>

                <TimelineItem>
                  <TimelineSeparator>
                      <TimelineDot color='primary'>
                        <Crop32 />
                      </TimelineDot>
                      <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                      <Typography variant="body2"><strong>Ordered</strong></Typography>
                      <Typography variant="caption">{formatDate(order.date)}</Typography>
                  </TimelineContent>
                </TimelineItem>
                {order.status === "Cancelled" ? (
                  <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color='error'>
                          <CancelOutlined />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="body2"><strong>Cancelled</strong></Typography>
                        <Typography variant="caption">{formatDate(order.cancelled_datetime)}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                ) : null}
                {order.shipped_status === "shipped" ? (
                  <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color='primary'>
                          <LocalShipping />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="body2"><strong>Shipped</strong></Typography>
                        <Typography variant="caption">{formatDate(order.shipped_datetime)}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                ) : null}
                {order.delivery_status === "Deliverd" ? (
                  <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color='success'>
                          <CheckCircle />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="body2"><strong>Delivered</strong></Typography>
                        <Typography variant="caption">{formatDate(order.delivery_datetime)}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                ) : null}
                </Timeline>
                {order.delivery_status === "Deliverd" ? (
                  <Button variant="contained" startIcon={<Download />} onClick={() => handleDownloadInvoice(order.id)}>Download Invoice</Button>
                ) : null}
                {order.eligible_for_return === "1" ? (
                  <Button variant="contained" startIcon={<KeyboardReturnOutlined />} onClick={() => handleReturnExchange(order.id)}>Return/Exchange</Button>
                ) : null}
                {/* <Invoice order={invoice_orders[index]} /> */}
                {order.cancellable && (
                <Alert severity="warning" sx={{ mt: 3 }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography><strong>This order is eligible for cancellation.</strong></Typography>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<Cancel />}
                      onClick={() => cancelOrder(order.id)}
                    >
                      Cancel Order
                    </Button>
                  </Grid>
                </Alert>
              )}
            </CardContent>
          </Collapse>
          <CardActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
            <Typography variant="body2"><strong>Order Status:</strong> {order.status}</Typography>
            <Typography variant="body2"><strong>Order Total:</strong> &#8377; {order.totalAmount}</Typography>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
}

// Manage Addresses Component
function ManageAddresses() {
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(1);
  const [mode, setMode] = useState('add');
  const [addressUpdated, setAddressUpdated] = useState(false);
  const [editedId, setEditedId] = useState("");
  const [addresses, setAddresses] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get('sessionToken');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contact_name: '',
    contact_phone: '',
    address: '',
    zip: '',
    state: '',
    city: '',
    address_type: '',
    is_default: '',
  });

  const handleOpenEdit = (id) => {
    setLoading(true);
    axios.post(baseURL + "/get_single_address_details", {"id":id}).then((response) => {
      if(response.data.status === 'success'){
        setFormData(response.data.data);
        setEditedId(id.toString())
        setOpen(true);
        setMode('edit')
        setLoading(false);
      }else{
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
      }
    })
  }
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    axios.post(baseURL + "/get_address_details", {"id":JSON.parse(token)['id']}).then((response) => {
      if(response.data.status === 'success'){
        setAddresses(response.data.data);
        setLoading(false);
      }else{
        navigate('/');
        setLoading(false);
      }
    })
  },[addressUpdated])

  const handleOpenNew = () => {
    setLoading(true);
    axios.post(baseURL + "/get_user_details", {"id":JSON.parse(token)['id']}).then((response) => {
      if(response.data.status === 'success'){
        setFormData({
          contact_name: response.data.data.name,
          contact_phone: response.data.data.phone
        })
        setMode('add');
        setOpen(true);
        setLoading(false);
      }else{
        navigate('/');
        setLoading(false);
      }
    })
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    
    if(!formData.contact_name || formData.contact_name === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter contact name',
      });
      setLoading(false)
      return false;
    }
    if(!formData.contact_phone || formData.contact_phone === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter contact phone',
      });
      setLoading(false)
      return false;
    }
    if(!formData.address || formData.address === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter address',
      });
      setLoading(false)
      return false;
    }
    if(!formData.zip || formData.zip === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter zip',
      });
      setLoading(false)
      return false;
    }
    if(!formData.state || formData.state === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter state',
      });
      setLoading(false)
      return false;
    }
    if(!formData.city || formData.city === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter city',
      });
      setLoading(false)
      return false;
    }
    if(!formData.address_type || formData.address_type === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter address type',
      });
      setLoading(false)
      return false;
    }
    axios.post(baseURL + "/update_user_address", {"id":JSON.parse(token)['id'],"formData":formData,"mode":mode,"editedId":editedId}).then((response) => {
      if(response.data.status === 'success'){
        Swal.fire({
          icon: 'success',
          text: 'Address updated successfully.',
        });
        if(addressUpdated === false){
          setAddressUpdated(true)
        }else{
          setAddressUpdated(false)
        }
        setLoading(false)
        setMode('');
        setOpen(false);
        return false;
      }else if(response.data.status === 'duplicate'){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An address with this type already exists. Please edit the existing address or select a different address type.',
        });
        setLoading(false)
        return false;
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong, Please try agian!',
        });
        setLoading(false)
        return false;
      }
    })
  }
  return (
    <Box>
      {loading ? <Loader /> : ""}
      {/* Address List */}
      {addresses.map((item) => (
        <Box
          key={item.id}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          border={1}
          borderColor="grey.300"
          borderRadius={2}
          mb={2}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={item.is_default === "1"}
                onChange={() => setSelectedAddress(item.id)}
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {item.type}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.address}
                </Typography>
              </Box>
            }
          />
          <IconButton color="primary" onClick={(e) => handleOpenEdit(item.id)}>
            <Edit />
          </IconButton>
        </Box>
      ))}

      {/* Add New Address Button */}
      <Button variant="outlined" color="primary" fullWidth onClick={handleOpenNew} size="large">
        Add New Address
      </Button>

      {/* New Address Dialog (Popup) */}
      <Dialog className='profile_address_popup' open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Address</DialogTitle>
        <DialogContent>
          <Box component="form" mt={1} noValidate autoComplete="off">
            <Box display="flex" gap={2} mb={2}>
              <TextField label="Contact Name" name="contact_name" variant="outlined" fullWidth required value={formData.contact_name} onChange={handleChange}/>
            </Box>
            <TextField label="Contact Phone Number" name="contact_phone" variant="outlined" fullWidth required value={formData.contact_phone} onChange={handleChange} sx={{ mb: 2 }} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  +91
                </InputAdornment>
              ),
              inputProps: { maxLength: 10 }, // Limit phone number length if needed
            }}/>
            <TextField label="Street Address" name="address" variant="outlined" fullWidth required sx={{ mb: 2 }} value={formData.address} onChange={handleChange}/>
            <Box display="flex" gap={2} mb={2}>
            <TextField label="Zip Code" name="zip" variant="outlined" fullWidth required value={formData.zip} onChange={handleChange}/>
            </Box>
            <Box display="flex" gap={2} mb={2}>
              <TextField
                label="State"
                variant="outlined"
                name="state"
                select
                SelectProps={{ native: true }}
                fullWidth
                value={formData.state}
                onChange={handleChange}
                required
              >
                <option value="">Select State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Puducherry">Puducherry</option>
                {/* Add more states as needed */}
              </TextField>
              <TextField label="City" name="city" variant="outlined" fullWidth required value={formData.city} onChange={handleChange}/>
            </Box>
            <Box display="flex" gap={2} mb={2}>
              <TextField label="Type Of Address" name="address_type" placeholder='Ex. Home,Office' variant="outlined" fullWidth value={formData.address_type} onChange={handleChange}/>
            </Box>
            <Box display="flex" gap={2} mb={2}>
              <FormControlLabel control={<Checkbox name="is_default" defaultChecked checked={formData.is_default} onChange={handleChange}/>} label="Make this as my default address" />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save Address
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// Reward Points Component
function Reward() {
    return (
      <Container maxWidth="md" sx={{ py: 5 }}>
      {/* Reward Balance */}
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} display="flex" justifyContent="center">
              <Avatar sx={{ bgcolor: '#FFD700', width: 64, height: 64 }}>
                <Star fontSize="large" />
              </Avatar>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h5">Total Points</Typography>
              <Typography variant="h3" color="primary">0</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Recent Activities */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Recent Activities
        </Typography>
        <Grid container spacing={2}>
          {[
            { id: 1, title: 'Earned for Purchase', date: 'Jan 1, 2025', points: 0 },
            { id: 2, title: 'Bonus Points', date: 'Jan 1, 2025', points: 0 },
            { id: 3, title: 'Referral Points', date: 'Jan 1, 2025', points: 0 },
          ].map((activity) => (
            <Grid item xs={12} sm={4} key={activity.id}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <History color="action" />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="subtitle1">{activity.title}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {activity.date}
                      </Typography>
                      <Typography variant="subtitle2" color="primary">
                        +{activity.points} pts
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Redeem Points */}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Redeem Points
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Exchange your points for exclusive discounts and vouchers!
        </Typography>
        <Button
          variant="contained"
          startIcon={<Redeem />}
          sx={{ mt: 2 }}
          size="large"
        >
          Redeem Now
        </Button>
      </Box>
    </Container>
    );
  }

// Manage Addresses Component
function Refer() {
  const [copied, setCopied] = useState(false);
  const referralLink = "https://thirtyseven.in";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Refer a Friend and Earn Points!',
          text: 'Join our platform using my referral link and earn rewards!',
          url: referralLink,
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };
  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      {/* Referral Card */}
      <Card sx={{ textAlign: 'center', p: 3 }}>
        <CardContent>
          <Avatar sx={{ bgcolor: '#FFD700', width: 64, height: 64, mx: 'auto', mb: 2 }}>
            <PersonAdd fontSize="large" />
          </Avatar>
          <Typography variant="h5">Refer A Friend</Typography>
          {/* <Typography variant="h5">Earn 50 Points</Typography>
          <Typography variant="body1" color="textSecondary">
            Share your unique link with friends, and earn points when they join and make a purchase!
          </Typography> */}

          {/* Referral Link */}
          <Box sx={{ my: 3 }}>
            <TextField
              fullWidth
              variant="outlined"
              value={referralLink}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopy}
                    startIcon={<FileCopy />}
                  >
                    {copied ? "Copied!" : "Copy"}
                  </Button>
                ),
              }}
            />
          </Box>

          {/* Social Sharing Buttons */}
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleShare}
                startIcon={<Share />}
                sx={{ mt: 2 }}
              >
                Share with Friends
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Footer */}
      {/* <Typography variant="body2" color="textSecondary" align="center">
        Terms and conditions apply. Points are awarded only when your friend successfully joins or makes a purchase.
      </Typography> */}
    </Container>
  );
}

export default ProfilePage;
