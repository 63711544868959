import { useState, useEffect } from "react";
import { Box, TextField, MenuItem, Button, FormControlLabel, Checkbox, Container } from "@mui/material";
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from "../../components/Loader"; // Import the Loader component
import axios from 'axios';
import { baseURL } from '../../components/base_url';
import Cookies from 'js-cookie';

export default function ReturnExchangeForm() {
    const [formData, setFormData] = useState({
        type: "",
        reason: "",
        otherReason: "",
        size: "",
        image: null,
        agree: false,
    });
    const location = useLocation();
    const navigate = useNavigate();
    const pathname = location.pathname;
    const { id } = location.state || "";
    const reasons = ["Damaged Item", "Wrong Item Received", "Size Issue", "Other"];
    const sizes = ["S", "M", "L", "XL"];
    const token = Cookies.get('sessionToken');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
        setFormData({ ...formData, image: file });
        } else {
            Swal.fire({
                icon: 'error', // Icon type: 'success', 'error', 'warning', 'info', 'question'
                title: 'Oops...',
                text: 'Please upload a valid image file.',
            });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on route change
    }, [pathname]);

    const removeImage = () => {
        setFormData({ ...formData, image: null });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.type || !formData.reason || (formData.reason === "Other" && !formData.otherReason) || !formData.image) {
        Swal.fire({
            icon: 'error', // Icon type: 'success', 'error', 'warning', 'info', 'question'
            title: 'Oops...',
            text: 'All fields are mandatory.',
        });
        return;
        }
        if(token == undefined){
            navigate('/ProductList');
        }
        
        const foundUser = JSON.parse(token);
        const formDataToSend = new FormData();
        formDataToSend.append("type", formData.type);
        formDataToSend.append("reason", formData.reason === "Other" ? formData.otherReason : formData.reason);
        if (formData.type === "Exchange") {
        formDataToSend.append("size", formData.size);
        }
        formDataToSend.append("image", formData.image);
        formDataToSend.append("order_id", id);
        formDataToSend.append("user_id", foundUser['id']);

        try {
        setLoading(true)
        const response = await fetch(baseURL +"/return_exchange", {
            method: "POST",
            body: formDataToSend,
        });
        setLoading(false)
        const result = await response.json();
            if(result.status == "success"){
                Swal.fire({
                    icon: 'success', 
                    text: `We have received your ${formData.type} request. Our team will review it, and we will update you regarding the confirmation soon. Thank you for your patience!`,
                }).then(() => {
                    navigate("/ProfilePage"); // Navigate to home page after clicking OK
                });
            }else{
                Swal.fire({
                    icon: 'error', // Icon type: 'success', 'error', 'warning', 'info', 'question'
                    title: 'Oops...',
                    text: result.message,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error', // Icon type: 'success', 'error', 'warning', 'info', 'question'
                title: 'Oops...',
                text: error,
            });
        }
    };

    return (
        <Container maxWidth="md">
            {loading ? <Loader /> : ""}
            <h4 className="mb-4">Return/Exchange</h4>
            <Box component="form" mt={1} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Box display="flex" gap={2}>
                    <TextField
                        label="Select Type"
                        name="type"
                        select
                        fullWidth
                        required
                        value={formData.type}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="">Select Type</MenuItem>
                        <MenuItem value="Return">Return</MenuItem>
                        <MenuItem value="Exchange">Exchange</MenuItem>
                    </TextField>
                </Box>
                <Box display="flex" gap={2}>
                    <TextField
                        label="Reason for Return/Exchange"
                        name="reason"
                        select
                        fullWidth
                        required
                        value={formData.reason}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="">Select a reason</MenuItem>
                        {reasons.map((r) => (
                        <MenuItem key={r} value={r}>{r}</MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box display="flex" gap={2}>
                    {formData.reason === "Other" && (
                        <TextField
                        label="Other Reason"
                        name="otherReason"
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                        value={formData.otherReason}
                        onChange={handleChange}
                        />
                    )}
                </Box>
                <Box display="flex" gap={2}>
                    {formData.type === "Exchange" && (
                        <TextField
                        label="Select Size"
                        name="size"
                        select
                        fullWidth
                        required
                        value={formData.size}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                        >
                        <MenuItem value="">Select Size</MenuItem>
                        {sizes.map((s) => (
                            <MenuItem key={s} value={s}>{s}</MenuItem>
                        ))}
                        </TextField>
                    )}
                </Box>
                <Box display="flex" gap={2}>
                    <Button variant="contained" component="label" sx={{ mb: 2 }}>
                        Upload Image
                        <input type="file" hidden accept="image/*" onChange={handleImageUpload} />
                    </Button>
                </Box>
                <Box display="flex" gap={2}>
                    {formData.image && (
                        <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <img src={URL.createObjectURL(formData.image)} alt="Uploaded Preview" width={100} height={100} style={{ objectFit: "cover" }} />
                        <Button variant="outlined" color="error" onClick={removeImage}>Cancel</Button>
                        </Box>
                    )}
                </Box>
                <Box display="flex" gap={2}>
                    <FormControlLabel
                        control={<Checkbox name="agree" checked={formData.agree} onChange={handleChange} />}
                        label="I agree to the return policy"
                    />
                </Box>
                <Box display="flex" gap={2} mb={2}>
                    <Button type="submit" variant="contained" color="primary">
                        Submit Request
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
