import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { TbShoppingBagCheck } from "react-icons/tb";
import { baseURL } from '../../components/base_url';
import Loader from "../../components/Loader"; // Import the Loader component
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import axios from 'axios';


function OrderConfirmation() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [estimatedDelivery, setEstimatedDelivery] = useState("");
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [transactionId,setTransactionId] = useState("");
     
    const ProductList = () => {
        navigate('/ProductList');
    } 
    useEffect(() => {
        const fetchPaymentStatus = async () => {
            const searchParams = new URLSearchParams(location.search);
            setTransactionId(searchParams.get("transactionId"));
            if (searchParams.get("transactionId")) {
                try {
                    const response = await fetch(baseURL + `/check_payment_status?transactionId=${searchParams.get("transactionId")}`);
                    const data = await response.json();
                    if (data.success && data.code === "PAYMENT_SUCCESS") {
                        const response = await fetch(baseURL + `/payment_success_status?transactionId=${searchParams.get("transactionId")}`);
                        const result = await response.json();
                        setEstimatedDelivery(result.estimated_delivery)
                        setLoading(false)
                    } else {
                        const response = await fetch(baseURL + `/payment_failed_status?transactionId=${searchParams.get("transactionId")}`);
                        Swal.fire({
                            icon: 'warning',
                            text: 'An error occurred while checking the payment status. If any amount was deducted, it will be refunded within 3-7 business days.',
                        });
                        navigate('/Checkout');
                        return false
                    }
                } catch (error) {
                    const response = await fetch(baseURL + `/payment_failed_status?transactionId=${searchParams.get("transactionId")}`);
                    Swal.fire({
                        icon: 'warning',
                        text: 'An error occurred while checking the payment status. If any amount was deducted, it will be refunded within 3-7 business days.',
                    });
                    navigate('/Checkout');
                    return false
                }
            } else {
                const response = await fetch(baseURL + `/payment_failed_status?transactionId=${searchParams.get("transactionId")}`);
                Swal.fire({
                    icon: 'warning',
                    text: 'No transaction ID found.',
                });
                navigate('/Checkout');
                return false
            }
        };

        fetchPaymentStatus();
    }, [location]);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on route change
    }, [pathname]);
    return (
        <>
        {loading ? <Loader /> : ""}
        <Container className="mt-5">
            {/* Header Section */}
            <Row className="text-center mb-4">
                <Col>
                    <h1 className='d-flex justify-content-center text-success'><TbShoppingBagCheck size={50}/> <span className='d-flex align-items-center'>Order Success</span></h1>
                    <h2>THANKS FOR YOUR ORDER!</h2>
                    <p>Your order <strong>#{transactionId}</strong> is in the works. We sent an email with your order receipt.</p>
                </Col>
            </Row>

            {/* Order Details Section */}
            <Row className="mb-4">
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>📅 Estimated Arrival</Card.Title>
                            <Card.Text>
                                {estimatedDelivery}
                                <br />
                                To ensure prompt delivery, some items may ship separately.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>📍 Shipping To</Card.Title>
                            <Card.Text>
                                Tracking information will be available once your order ships.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>❓ Have a Question?</Card.Title>
                            <Card.Text>
                                <p><strong><a href="tel:+917989404096">+91 7989404096</a></strong></p>
                                <Button variant="link" component="a" href="mailto:contact@thirtyseven.in">EMAIL US</Button>
                                <br />
                                <Button variant="link" onClick={() => navigate('/ContactUs')}>Contact Us</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Footer Section */}
            <Row className="text-center mb-4">
                <Col>
                    <button type="button" onClick={ProductList} className='btn btn-outline-success'>Continue Shopping</button>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default OrderConfirmation;
