import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './AuthModal.css';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import { baseURL } from '../components/base_url';
import Loader from "../components/Loader";
import { jwtDecode as jwt_decode } from 'jwt-decode';
import Cookies from 'js-cookie';

const CLIENT_ID = '961946269166-94rn3okrcqi8u0ou459vgvl1s2pinefq.apps.googleusercontent.com'; // Replace with your Google client ID

const AuthModal = ({ show, onClose, onLoginSuccess }) => {
    const [step, setStep] = useState('login'); // Tracks login/OTP flow
    const [input, setInput] = useState('+91'); // Stores email/phone input
    const [inputOtp, setInputOtp] = useState(''); // Stores email/phone input
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageOtp, setErrorMessageOtp] = useState(false);
  
    const handleSubmitLogin = (e) => {
      setLoading(true);
      const phoneRegex = /^[0-9+]{13}$/;
      if (!phoneRegex.test(input)) {
        setErrorMessage("Invalid Phone Number.")
        setLoading(false);
        return false;
      }
      axios.post(baseURL + "/send-otp", {"phone_number":input}).then((res) => {
        if(res.data.status === 'success'){
          setStep('otp')
          setErrorMessage("")
        }else{
          setErrorMessage("Failed to generate OTP!")
        }
        setLoading(false);
      })
    }
    const handleInputChange = (e) => {
      let value = e.target.value;
      // Ensure "+91" is always present and restrict input to numbers
      if (!value.startsWith("+91")) {
        value = "+91"
      }
      
      let numbersOnly = value.replace(/\D/g, "").slice(2);
      numbersOnly = numbersOnly.slice(0, 10);
      setInput("+91" + numbersOnly);
      setErrorMessage("")
    };
    const handleInputChangeOtp = (e) => {
      setInputOtp(e.target.value);
      setErrorMessageOtp("")
    }
    const handleSubmitOTP = (e) => {
      setLoading(true);
      const phoneRegex = /^[0-9]{4}$/;
      if (!phoneRegex.test(inputOtp)) {
        setErrorMessageOtp("Enter valid OTP.")
        setLoading(false);
        return false;
      }
      axios.post(baseURL + "/phone_auth", {"phone":input,"otp":inputOtp}).then((response) => {
        if(response.data.status === 'success'){
          onClose();
          Cookies.set('sessionToken', JSON.stringify({'name': "Hello",'id': response.data.id}), { secure: true, sameSite: 'Strict' });
          if (onLoginSuccess) {
            onLoginSuccess();
          }
          window.location.reload();
          setLoading(false);
          setErrorMessageOtp("")
        }else{
          setLoading(false);
          setErrorMessageOtp("Invalid OTP.")
        }
      })
    }
    const handleGoogleSuccess = (response) => {
      setLoading(true);
      setErrorMessage("")
      const token = response.credential;
      const decoded = jwt_decode(token);
      if(decoded.email_verified == false){
        setErrorMessage("Failed to login, Please try again!")
        setLoading(false);
        return false;
      }
      const post_data = {
        "email": decoded.email,
        "email_verified": decoded.email_verified,
        "name": decoded.name,
        "picture": decoded.picture,
      }
      axios.post(baseURL + "/email_auth", post_data)
      .then(response => {
        if(response.data.status == "success"){
          onClose();
          Cookies.set('sessionToken', JSON.stringify({'name': decoded.name,'id': response.data.id}), { secure: true, sameSite: 'Strict' });
          if (onLoginSuccess) {
            onLoginSuccess();
          }
          window.location.reload();
          setLoading(false);
        }else{
          setErrorMessage("Failed to login, Please try again!")
          setLoading(false);
        }
      })
      .catch(error => {
        setErrorMessage("Failed to login, Please try again!")
        setLoading(false);
      });
    };
  
    const handleGoogleError = (error) => {
      console.error('Google Login Failed:', error);
    };
    const verifyInfo = () =>{
        setStep('login')
    }
  
    if (!show) return null;

  return (
    <div className="modal-overlay">
      {loading ? <Loader /> : ""}
      <div className="modal-container login_modal">
        <div className="modal-header">
          <h2>{step === 'login' ? 'Login / Signup' : 'Enter OTP'}</h2>
          <MdClose className="close-icon" onClick={onClose} />
        </div>

        <div className="modal-body">
          {step === 'login' ? (
            <>
              <input
                type="text"
                placeholder="Enter Phone Number"
                value={input}
                onChange={handleInputChange}
                required
                maxLength={13}
              />
              <p className='error-class'>{errorMessage}</p>
              <button
                className="continue-btn"
                disabled={!input}
                onClick={handleSubmitLogin}
              >
                Continue
              </button>

              <div className="divider">OR</div>

              <GoogleOAuthProvider clientId={CLIENT_ID}>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                />
              </GoogleOAuthProvider>
            </>
          ) : (
            <>
              <input type="text" placeholder="Enter OTP" value={inputOtp} onChange={handleInputChangeOtp} required />
              <p className='error-class'>{errorMessageOtp}</p>
              <button className="continue-btn" onClick={handleSubmitOTP}>Verify OTP</button>
              <button className="back-btn" onClick={() => setStep('login')}>
                Back to Login
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
