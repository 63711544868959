import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseURL } from '../../components/base_url';

const PaymentStatus = () => {
    const location = useLocation();
    const [status, setStatus] = useState("Checking payment...");

    useEffect(() => {
        const fetchPaymentStatus = async () => {
            const searchParams = new URLSearchParams(location.search);
            const transactionId = searchParams.get("transactionId");

            if (transactionId) {
                try {
                    const response = await fetch(baseURL + `/check_payment_status?transactionId=${transactionId}`);
                    const data = await response.json();

                    if (data.success && data.code === "PAYMENT_SUCCESS") {
                        setStatus("Payment Successful 🎉");
                    } else {
                        setStatus("Payment Failed ❌");
                    }
                } catch (error) {
                    setStatus("Error checking payment status.");
                }
            } else {
                setStatus("No transaction ID found.");
            }
        };

        fetchPaymentStatus();
    }, [location]);

    return (
        <div>
            <h2>{status}</h2>
        </div>
    );
};

export default PaymentStatus;
