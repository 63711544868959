// Header.js
import React, { useState, useEffect } from 'react';
import "./style.css";
import Sidebar from './Sidebar';
import axios from 'axios';
import { baseURL } from '../components/base_url';

function Header({ selectedWishlistCount, selectedBagCount }) {
    const [topLeftContent, setTopLeftContent] = useState("")
    const [topRightContent, setTopRightContent] = useState("")
    
    const [showSideNav, setShowSideNav] = useState(false);
    const toggleSideNav = () => setShowSideNav(!showSideNav);
    const closeSideNav = () => setShowSideNav(false);
    useEffect(() => {
      axios.get(baseURL + "/header_promocode").then((res) => {
        if(res.data.status === 'success'){
          setTopLeftContent("It's Offers Time! ")
          setTopRightContent(res.data.data)
        }else{
          setTopLeftContent("")
          setTopRightContent("")
        }
      })
    })
    return (
        <>
        <div className="top-bar">
          <marquee className="left-content right-content"><span style={{fontSize:"large"}}>{topLeftContent}</span>{topRightContent}</marquee>
        </div>
        <Sidebar wishlistCount={selectedWishlistCount} bagCount={selectedBagCount}/>
      </>
    );
  }

export default Header;
